<template>
  <nav class="alphabet-nav" aria-label="Navigazione alfabetica">
    <ul class="alphabet-list" role="toolbar">
      <li v-for="letter in alphabet" :key="letter" class="alphabet-item">
        <button
            class="alphabet-button"
            :class="{ 'active': activeLetter === letter }"
            @click="selectLetter(letter)"
            :aria-pressed="activeLetter === letter"
            :aria-label="`Filtra professioni che iniziano con la lettera ${letter}`"
        >
          {{ letter }}
        </button>
        |
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'AlphabetNavigation',
  props: {
    // Allow parent to specify the active letter if needed
    initialLetter: {
      type: String,
      default: 'A'
    }
  },
  data() {
    return {
      alphabet: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split(''),
      activeLetter: this.initialLetter
    };
  },
  methods: {
    selectLetter(letter) {
      this.activeLetter = letter;
      this.$emit('letterSelected', letter);

      // Announce change for screen readers
      this.announceLetterChange(letter);
    },
    announceLetterChange(letter) {
      // Create a live region announcement for screen readers
      const announcement = document.createElement('div');
      announcement.setAttribute('aria-live', 'polite');
      announcement.classList.add('sr-only');
      announcement.textContent = `Selezionata lettera ${letter}`;

      document.body.appendChild(announcement);

      // Remove it after it's been announced
      setTimeout(() => {
        document.body.removeChild(announcement);
      }, 1000);
    }
  }
};
</script>

<style scoped>
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.alphabet-nav {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto 40px;
  padding: 0 15px;
}

.alphabet-list {
  display: flex;
  flex-wrap: nowrap;
  padding: 5px 10px;
  margin: 0;
  list-style: none;
  border-radius: 4px;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: #ccc transparent; /* Firefox */
}

/* Custom scrollbar for Webkit browsers */
.alphabet-list::-webkit-scrollbar {
  height: 2px;
}

.alphabet-list::-webkit-scrollbar-track {
  background: transparent;
}

.alphabet-list::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 4px;
}

.alphabet-item {
  flex: 0 0 auto;
  margin: 0;
  padding: 0px;
  font-size: 12px;
}

.alphabet-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 30px;
  height: 30px;
  padding: 0 1px;
  background: transparent;
  border: none;
  border-radius: 3px;
  color: #013747;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: color 0.2s ease;
}

.alphabet-button:hover {
  color: #3BFF3B;
}

.alphabet-button:focus {
  outline: none;
  color: #3BFF3B;
}

.alphabet-button.active {
  color: #3BFF3B;
  font-weight: bold;
}

/* Mobile styles */
@media (max-width: 992px) {
  .alphabet-nav {
    width: 92%;
    padding: 0;
  }

  .alphabet-list {
    background-color: #D2D3D3;
    padding: 12px 15px;
    border-radius: 0;
    /* Add more space at both ends for scrolling */
    padding-left: 20px;
    padding-right: 20px;
  }

  .alphabet-button {
    min-width: 36px;
    height: 36px;
    font-size: 18px;
    margin: 0 3px;
  }
}
</style>