<template>
  <div>
    <div class="popup" :class="{ 'visible': isVisible }"
         role="dialog"
         aria-modal="true"
         aria-labelledby="search-popup-title">
      <div class="popup-content" :class="{ 'visible': isVisible }" ref="popupContent">
        <div class="search-header">
          <a href="/" class="logo-link" tabindex="-1">
            <img src="@/assets/logo-atlante.svg" alt="Atlante delle Professioni Logo" style="height: 65px">
          </a>
          <h2 id="search-popup-title" class="sr-only">Ricerca nell'Atlante delle Professioni</h2>
          <button class="close-btn" @click="closePopup" ref="closeButton" aria-label="Chiudi la ricerca">
            <img src="@/assets/close-btn-icon.png" alt="" aria-hidden="true"/>
          </button>
        </div>

        <div class="search-input-container">
          <label for="search-popup-input" class="sr-only">Cerca professioni, aree o specializzazioni</label>
          <input
              id="search-popup-input"
              ref="searchInput"
              v-model="searchQuery"
              @input="filterProfessions"
              @keyup.enter="emitSearchQuery"
              @keydown.tab="handleTabKey"
              @keydown.esc="closePopup"
              type="text"
              placeholder="Cerca..."
              class="search-input"
              aria-describedby="search-instructions"
          />
          <div id="search-instructions" class="sr-only">
            Digita almeno 2 caratteri per avviare la ricerca. Usa il tasto Tab per navigare tra i risultati.
          </div>
          <img class="search-icon" src="@/assets/icon_search.svg" alt="" aria-hidden="true"/>
          <div v-if="isLoading" class="loading-indicator" aria-live="polite">
            <span class="sr-only">Caricamento risultati in corso...</span>
            <div class="spinner"></div>
          </div>
        </div>

        <div v-if="showNoResults"
             class="no-results"
             role="status"
             aria-live="polite">
          <span v-if="isLoading">Ricerca in corso...</span>
          <span v-else>Nessun risultato trovato per "{{ searchQuery }}"</span>
        </div>

        <div v-if="filteredProfessions.length > 0"
             class="dropdown"
             role="listbox"
             aria-label="Risultati della ricerca">
          <div v-for="(profession, index) in filteredProfessions"
               :key="profession.id"
               class="dropdown-item"
               role="option"
               :id="`result-${index}`">
            <a :href="`/professioni/${profession.attributes.identifier}`"
               ref="resultLinks"
               @keydown.tab.exact="handleResultTabKey($event, index)"
               @keydown.shift.tab="handleResultShiftTabKey($event, index)"
               :tabindex="isVisible ? 0 : -1">
              {{ profession.attributes.title }}
            </a>
            <div v-html="truncatedDescription(profession.attributes.identity.description)"
                 class="profession-description">
            </div>
          </div>
        </div>

        <!-- Focus catcher at end of popup content -->
        <span tabindex="0"
              ref="lastFocusable"
              @focus="focusCloseButton"
              class="sr-only">
          Fine dei risultati. Premere Tab per tornare all'inizio della finestra di ricerca.
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import {fetchProfessions} from "@/services/apiService";

export default {
  name: "SearchPopupComponent",
  props: {
    isVisible: Boolean
  },
  data() {
    return {
      searchQuery: '',
      professions: [],
      filteredProfessions: [],
      previousFocus: null,
      isLoading: false,
      dataLoaded: false
    }
  },
  computed: {
    showNoResults() {
      return this.searchQuery &&
          this.searchQuery.length >= 2 &&
          (this.isLoading || this.filteredProfessions.length === 0);
    }
  },
  created() {
    // Start loading data, but don't block the UI
    this.loadProfessionsData();
  },
  mounted() {
    // Add event listener for escape key
    document.addEventListener('keydown', this.handleEscKey);
  },
  beforeUnmount() {
    // Clean up event listener
    document.removeEventListener('keydown', this.handleEscKey);
  },
  watch: {
    isVisible(newVal) {
      if (newVal) {
        // Store the current active element before showing the popup
        this.previousFocus = document.activeElement;

        // Set up focus trap after component is rendered
        this.$nextTick(() => {
          this.setupFocusTrap();

          // Focus the search input when popup is visible
          this.$refs.searchInput.focus();

          // Prevent background scrolling
          document.body.style.overflow = 'hidden';

          // If we have a searchQuery but no filtered results,
          // and the data has loaded, re-filter with the current query
          if (this.searchQuery &&
              this.searchQuery.length >= 2 &&
              this.filteredProfessions.length === 0 &&
              this.dataLoaded) {
            this.filterProfessions();
          }
        });
      } else {
        // Reset search when closing
        this.searchQuery = '';
        this.filteredProfessions = [];

        // Return focus to the element that opened the popup
        if (this.previousFocus) {
          this.previousFocus.focus();
        }

        // Re-enable scrolling
        document.body.style.overflow = '';
      }
    }
  },
  methods: {
    async loadProfessionsData() {
      if (this.dataLoaded || this.isLoading) return;

      this.isLoading = true;

      try {
        const data = await fetchProfessions();
        if (data && data.length > 0) {
          this.professions = this.populateDenominationsAndSpecializations(data);
          this.dataLoaded = true;

          // If user has already entered a search query, filter the results
          if (this.searchQuery && this.searchQuery.length >= 2) {
            this.filterProfessions();
          }
        }
      } catch (error) {
        console.error('Error loading professions:', error);
      } finally {
        this.isLoading = false;
      }
    },
    filterProfessions() {
      // Reset filtered professions if query is too short
      if (!this.searchQuery || this.searchQuery.length < 2) {
        this.filteredProfessions = [];
        return;
      }

      // If data isn't loaded yet, just return - we'll re-filter when data arrives
      if (!this.dataLoaded && !this.isLoading) {
        this.loadProfessionsData();
        return;
      }

      const query = this.searchQuery.toLowerCase().trim();

      // Filter based on available data, even if it's still loading
      this.filteredProfessions = this.professions
          .filter(profession =>
              profession.attributes.title.toLowerCase().includes(query)
          )
          .sort((a, b) => {
            // First sort by exact match at beginning
            const aStartsWithQuery = a.attributes.title.toLowerCase().startsWith(query);
            const bStartsWithQuery = b.attributes.title.toLowerCase().startsWith(query);

            if (aStartsWithQuery && !bStartsWithQuery) return -1;
            if (!aStartsWithQuery && bStartsWithQuery) return 1;

            // Then sort alphabetically
            return a.attributes.title.localeCompare(b.attributes.title);
          })
          .slice(0, 20); // Limit results to improve performance
    },
    emitSearchQuery() {
      this.$emit('search', this.searchQuery);

      // If only one result, navigate to it
      if (this.filteredProfessions.length === 1) {
        window.location.href = `/professioni/${this.filteredProfessions[0].attributes.identifier}`;
      }
    },
    closePopup() {
      this.$emit('close-popup');
    },
    populateDenominationsAndSpecializations(professions) {
      const newProfessions = [];

      professions.forEach(profession => {
        // Check if the denominazione array exists and is not empty
        if (profession.attributes.denominazione && profession.attributes.denominazione.length > 0) {
          profession.attributes.denominazione.forEach(denomination => {
            if (denomination.title) {
              let description = `<a style="margin-top: 15px">Altra denominazione di <span style="font-weight:bold;">${profession.attributes.title}</span></a>`;
              if (denomination.specializzazione) {
                description = `<a style="margin-top: 15px">Specializzazione di <span style="font-weight:bold;">${profession.attributes.title}</span></a>`;
              }

              // Create a new profession object for each denomination
              const newProfession = {
                id: `${profession.id}-${denomination.title.replace(/\s+/g, '-')}`,
                attributes: {
                  identifier: profession.attributes.identifier,
                  title: denomination.title,
                  identity: {
                    description: description
                  }
                }
              };
              newProfessions.push(newProfession);
            }
          });
        }
      });

      // Concatenate the new professions to the original professions array
      return [...professions, ...newProfessions];
    },
    setupFocusTrap() {
      // This will be called when the popup becomes visible
      // Focus the search input initially
      if (this.$refs.searchInput) {
        this.$refs.searchInput.focus();
      }
    },
    handleEscKey(event) {
      if (event.key === 'Escape' && this.isVisible) {
        this.closePopup();
      }
    },
    handleTabKey(event) {
      // If there are results and we're tabbing from the search input,
      // focus should go to the first result
      if (!event.shiftKey && this.filteredProfessions.length > 0 && this.$refs.resultLinks?.length > 0) {
        event.preventDefault();
        this.$refs.resultLinks[0].focus();
      } else if (event.shiftKey) {
        // If shift+tab, focus goes to close button
        event.preventDefault();
        this.$refs.closeButton.focus();
      }
    },
    handleResultTabKey(event, index) {
      // If we're at the last result and tabbing forward
      if (index === this.filteredProfessions.length - 1) {
        event.preventDefault();
        this.$refs.lastFocusable.focus();
      }
    },
    handleResultShiftTabKey(event, index) {
      // If we're at the first result and tabbing backward
      if (index === 0) {
        event.preventDefault();
        this.$refs.searchInput.focus();
      }
    },
    focusCloseButton() {
      // When the last focusable element gets focus, cycle back to close button
      this.$refs.closeButton.focus();
    },
    truncatedDescription(description) {
      const maxLength = 300;
      if (!description) return '';
      if (description.length > maxLength) {
        return description.slice(0, maxLength) + '...';
      } else {
        return description;
      }
    }
  }
}
</script>

<style scoped>
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.98);
  visibility: hidden;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  opacity: 0;
  transition: visibility 0s linear 0.4s, opacity 0.4s linear;
}

.popup.visible {
  display: flex;
  visibility: visible;
  opacity: 1;
  transition-delay: 0s; /* Apply transition delay reset for visibility */
}

.popup-content {
  visibility: hidden;
  display: flex;
  flex-direction: column; /* Changed to column for better layout control */
  justify-content: start; /* Align content to the start */
  background-color: #fff;
  padding: 25px;
  border-radius: 8px;
  width: 60%;
  max-width: 1100px;
  position: relative; /* For dropdown positioning */
  height: 90%;
  z-index: 2001;
  transform: scale(0.5); /* Start scaled down */
  opacity: 0; /* Start invisible */
  transition: transform 1s ease, opacity 0.4s ease;
}

.popup-content.visible {
  visibility: visible;
  display: flex;
  transform: scale(1); /* Scale to full size */
  opacity: 1; /* Fully opaque */
}

.search-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 70px;
}

.close-btn {
  border: none;
  cursor: pointer;
  background-color: white;
  padding: 8px;
  min-width: 44px; /* Minimum touch target size */
  min-height: 44px; /* Minimum touch target size */
}

.close-btn:focus {
  outline: 3px solid #3BFF3B;
  outline-offset: 2px;
}

.close-btn img {
  height: 30px;
}

.search-input-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
  position: relative;
}

.search-input {
  height: 50px;
  border: none;
  border-bottom: 2px solid limegreen;
  outline: none;
  font-size: 24px;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  padding-right: 40px; /* Make room for the search icon */
}

.search-input:focus {
  border-bottom: 2px solid #013747;
  outline: none;
}

.search-input::placeholder {
  font-size: 24px; /* Adjust the font size as needed */
  font-family: "Montserrat", sans-serif;
  color: #013747;
}

.search-icon {
  position: absolute;
  right: 10px;
  width: 24px;
  height: 24px;
}

.loading-indicator {
  position: absolute;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner {
  width: 24px;
  height: 24px;
  border: 3px solid rgba(59, 255, 59, 0.3);
  border-radius: 50%;
  border-top-color: #3BFF3B;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.no-results {
  text-align: center;
  padding: 20px;
  color: #666;
  font-style: italic;
}

.dropdown {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.dropdown-item {
  display: flex;
  flex-direction: column;
  border-bottom: 2px dotted limegreen;
  max-height: 130px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.dropdown-item a {
  text-decoration: none;
  font-weight: bold;
  font-size: 22px;
  color: #013747;
}

.dropdown-item a:hover,
.dropdown-item a:focus {
  color: limegreen;
  outline: none;
  text-decoration: underline;
}

.profession-description {
  margin-top: 8px;
  color: #666;
  overflow: hidden;
}

@media (max-width: 992px) {
  .popup-content {
    width: 90%;
    height: 85%;
    padding: 15px;
  }

  .search-header {
    margin-bottom: 40px;
  }

  .search-input {
    font-size: 20px;
  }

  .search-input::placeholder {
    font-size: 20px;
  }

  .dropdown-item a {
    font-size: 18px;
  }
}
</style>