<template>
  <div>
    <div v-if="isVisible" class="popup" id="pcontentt" role="dialog" aria-modal="true" aria-labelledby="video-popup-title">
      <!-- Trap focus within modal using hidden elements -->
      <span tabindex="0" @focus="focusLastElement" class="sr-only">Inizio del popup</span>

      <div class="popup-content" id="pcontent" v-if="videoData">
        <div class="related-info"
             v-if="videoData && videoData.attributes.related_profession && videoData.attributes.related_videos">

          <div class="related-material">
            <button @click="closePopup" class="close-btn" aria-label="Chiudi il popup" ref="closeButton">
              <img src="@/assets/close_white.png" alt="" aria-hidden="true"/>
            </button>
            <div>
              <div>
                <h6 class="video-sign">VIDEO</h6>
                <h1 class="video-title" id="video-popup-title" v-if="videoData.attributes.title">
                  {{ videoData.attributes.title }}</h1>
              </div>
              <div>
                <h3 v-if="videoData.attributes.type">
                  {{ videoData.attributes.type }}</h3>
              </div>
            </div>
            <div>
              <h6 v-if="videoData.attributes.related_profession.data && videoData.attributes.related_profession.data.attributes.title">
                VEDI ANCHE</h6>
            </div>
            <div class="related-videos"
                 v-if="videoData.attributes.related_videos.data && videoData.attributes.related_videos.data.length">
              <div v-for="relatedVideo in videoData.attributes.related_videos.data" :key="relatedVideo.id">
                <div v-if="relatedVideo.attributes.title !== videoData.attributes.title">
                  <Video style="font-size:10px;width: 150px;height: 240px" :videoObj="relatedVideo"
                         @open-popup="changeVideo"/>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="video-wrapper">
          <video ref="videoPlayer" class="actual-video" controls :src="getVideoFileUrl(videoData)"
                 :aria-label="videoData.attributes.title">
            Il tuo browser non supporta il tag video.
          </video>
        </div>
      </div>

      <!-- End of focus trap -->
      <span tabindex="0" @focus="focusFirstElement" class="sr-only">Fine del popup</span>
    </div>
  </div>
</template>

<script>
import Video from '@/components/VideosPage/VideoComponent.vue';
import {fetchSingleVideo} from "@/services/apiService";

export default {
  name: "VideoPopupComponent",
  components: {
    Video,
  },
  props: {
    videoId: {
      type: String,
      required: true
    },
    isVisible: Boolean
  },
  data() {
    return {
      videoData: null, // This will store the fetched video data
      previousFocus: null // Store the element that had focus before opening the popup
    }
  },
  watch: {
    isVisible(newVal) {
      if (newVal === true) {
        // Store the element that had focus before opening the popup
        this.previousFocus = document.activeElement;
        this.fetchVideoData();
        // Set up keyboard event listener for escape key
        document.addEventListener('keydown', this.handleKeyDown);
      } else {
        // Remove keyboard event listener when popup is closed
        document.removeEventListener('keydown', this.handleKeyDown);
        // Return focus to the element that had focus before opening the popup
        if (this.previousFocus) {
          this.previousFocus.focus();
        }
      }
    },
    videoId() {
      this.fetchVideoData();
    }
  },
  methods: {
    closePopup() {
      this.$emit('close-popup');
    },
    changeVideo(videoId) {
      console.log(videoId)
      this.$emit('change-popup', videoId);
    },
    getVideoFileUrl(video) {
      let videoLink = '';
      if (video.attributes.video_file && video.attributes.video_file.data) {
        videoLink = `${process.env.VUE_APP_BASE_BACK_URL}${video.attributes.video_file.data.attributes.url}`;
      } else if (video.attributes.video_link) {
        videoLink = `${video.attributes.video_link}`;
      }
      return videoLink;
    },
    async fetchVideoData() {
      if (this.videoId) {
        try {
          const videoData = await fetchSingleVideo(this.videoId); // Assuming fetchSingleVideo is an async function
          this.videoData = videoData;
          await this.$nextTick();
          await this.$nextTick();
          this.scrollToTop();
          // Focus on close button when popup opens and content is loaded
          this.$nextTick(() => {
            if (this.$refs.closeButton) {
              this.$refs.closeButton.focus();
            }
          });
        } catch (error) {
          console.error('Error fetching video data:', error);
        }
      }
    },
    scrollToTop() {
      window.scrollTo(0, 0); // Scroll to the top of the window
      const contentElement = document.getElementById('pcontent');
      const contenttElement = document.getElementById('pcontentt');
      if (contentElement) {
        contentElement.scrollTo(-3000, -3000);
        contenttElement.scrollTo(-3000, -3000);
      }
    },
    handleKeyDown(e) {
      // Close popup when Escape key is pressed
      if (e.key === 'Escape' && this.isVisible) {
        this.closePopup();
      }
    },
    // Focus management methods for the focus trap
    focusFirstElement() {
      if (this.$refs.closeButton) {
        this.$refs.closeButton.focus();
      }
    },
    focusLastElement() {
      const focusableElements = this.getFocusableElements();
      if (focusableElements.length > 0) {
        focusableElements[focusableElements.length - 1].focus();
      }
    },
    getFocusableElements() {
      const popup = document.getElementById('pcontent');
      if (!popup) return [];

      return Array.from(
          popup.querySelectorAll(
              'button, [href], input, select, textarea, video[controls], [tabindex]:not([tabindex="-1"])'
          )
      );
    }
  },
  mounted() {
    this.scrollToTop(); // Ensure the component scrolls to the top when it is mounted
  },
  beforeUnmount() {
    // Clean up event listeners
    document.removeEventListener('keydown', this.handleKeyDown);
  }
}

</script>

<style scoped>
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(2, 5, 0, 0.95); /* Green background with some transparency */
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease;
  touch-action: none; /* Disable default touch behaviors */
}

.popup-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  background-color: #3BFF3B; /* Light green background */
  width: 70%;
  max-width: 1200px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.5s ease;
}

.close-btn {
  position: absolute;
  left: 0;
  border: none;
  color: #fff; /* White text */
  background-color: transparent;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  align-items: center;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
}

.close-btn:focus {
  outline: 3px solid #fff;
  outline-offset: 3px;
}

.close-btn img {
  filter: brightness(0) invert(1);
  height: 18px;
  width: auto;
}

.video-sign {
  color: lime;
  margin-top: 65px;
  margin-bottom: 2px;
  font-family: "MontserratSemiBold", sans-serif;
  font-size: 11px;
  line-height: 19px;
  letter-spacing: 0.9px;
  text-transform: uppercase;
}


.video-wrapper {
  width: 70%;
}

.actual-video {
  height: 100%;
  width: 100%;
  background-color: #3BFF3B;
  object-fit: cover;
}

.popup-content video {
  box-shadow: 10px 15px 20px rgba(0, 0, 0, 0.2);
}

.video-title {
  font-size: 24px;
  line-height: 26px;
  font-family: "Montserrat";
  padding: 0;
  margin: 10px 0;
  flex: 2;
  color: hsl(0deg, 0%, 100%);
  display: block;
  border: none;
  margin-bottom: 0;
  max-width: 100%;
}

.related-info {
  padding: 10px;
  padding-bottom: 30px;
  width: 30%;
}


.related-info h3, .related-videos h4 {
  margin: 10px 0;
}

.related-material {
  background-color: #3BFF3B;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 5px;
  height: 100%;
  transition: all 0.5s ease;
  padding-left: 40px;
  position: relative;
}

.related-material h3 {
  color: white;
  margin-top: 65px;
  margin-bottom: 2px;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 19px;
  letter-spacing: 0.9px;
  text-transform: uppercase;
  margin-top: 10px;
}

.related-material h6 {
  font-family: "MontserratSemiBold", sans-serif;
  font-size: 11px;
  line-height: 19px;
  color: #013747;
  letter-spacing: 0.9px;
  text-transform: uppercase;
}

.related-videos {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 5px;
  overflow-x: auto; /* Add vertical scrollbar if needed */
  max-height: 250px; /* Set maximum height for scrollable area */
  max-width: 400px;
  scrollbar-width: thin;
  scrollbar-color: rgba(32, 117, 18, 0.6) rgba(36, 230, 2, 0.2); /* Lime green thumb and forestgreen track */
  overflow-y: auto;
  transition: all 0.5s ease;
}

.related-videos li::before {
  content: "▹";
  position: absolute;
  left: 0;
  color: #32cd32; /* Lime green */
}

@media (max-width: 1000px) {
  /* For WebKit browsers */
  #pcontentt::-webkit-scrollbar {
    width: 32px; /* Increase the width of the scrollbar */
  }

  #pcontentt::-webkit-scrollbar-track {
    background: whitesmoke; /* Light grey track */
  }

  #pcontentt::-webkit-scrollbar-thumb {
    background: darkgreen; /* Lime green thumb */
    border-radius: 22px; /* Rounded corners for the thumb */
  }

  #pcontentt::-webkit-scrollbar-thumb:hover {
    background: #28a745; /* Darker shade of green for hover effect */
  }

  /* For Firefox */
  #pcontentt {
    scrollbar-color: #32cd32 #f1f1f1; /* Lime green thumb on light grey track */
  }


  .popup {
    overflow-y: auto;
  }

  .popup-content {
    flex-direction: column-reverse;
    width: 85%;
    height: 80%; /* Adjust based on needs */
    max-height: 80vh; /* Prevent the popup from exceeding the viewport height */
    overflow-y: auto; /* Enable vertical scrolling */
    padding: 5px;
  }

  .video-wrapper, .related-material {
    width: 105%; /* Ensure elements within the popup use the full width available */
    max-width: 100%; /* Prevent overflow */
  }

  .video-wrapper {
    box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.5);
    border: 1px solid whitesmoke;
  }

  .actual-video {
    width: 100%;
    max-width: 100%;
    min-height: 270px;
  }

  .related-info {
    width: 100%;
  }

  .related-material {
    padding-left: 1%;
    padding-right: 0;
    overflow-x: auto;
  }

  .related-info {
    padding-right: 0;
    padding-left: 0;
  }
}
</style>