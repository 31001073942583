<template>
  <!-- Skip link should be the very first element -->
  <a href="#videos-container" class="skip-link">Vai ai video</a>

  <Header :showSearchButton="true"/>
  <main>
    <div class="container-all">
      <section class="content-videos">

        <div class="title-box">
          <h1>{{ pageTitle }}</h1>
          <h5 class="video-page-subtitle">{{ pageSubtitle }}</h5>
        </div>

        <div class="controls-container" role="search" aria-label="Filtri video">
          <div class="search-container">
            <label for="searchProfession" class="sr-only">Cerca per professione</label>
            <input
                type="text"
                v-model="searchProfession"
                id="searchProfession"
                placeholder="Cerca per professione"
                @input="updatePaginationAndResetPage"/>
          </div>

          <div class="sort-container">
            <label for="sortSelect" class="sr-only">Filtra per tipo di video</label>
            <select
                v-model="sortType"
                id="sortSelect"
                aria-label="Filtra per tipo di video">
              <option value="None">Tutti i video</option>
              <option value="Luogo">Luogo</option>
              <option value="Intervista">Intervista</option>
            </select>
          </div>
        </div>

        <div id="videos-container" class="videos-container" role="region" aria-label="Galleria video">
          <Video
              v-for="video in paginatedVideos"
              :key="video.id"
              :videoObj="video"
              @open-popup="openPopup"
          />
        </div>

        <VideoPopupComponent
            :videoId="selectedVideoId"
            :isVisible="isPopupOpen"
            @close-popup="closePopup"
            @change-popup="changePopup"
        />

        <nav class="pagination" aria-label="Paginazione video">
          <button
              @click="changePage(currentPage - 1)"
              :disabled="currentPage <= 1"
              aria-label="Pagina precedente">
            Prev
          </button>

          <!-- Page Numbers -->
          <button
              class="page-number"
              v-for="page in totalPages"
              :key="page"
              @click="changePage(page)"
              :class="{ active: currentPage === page }"
              :aria-label="'Pagina ' + page"
              :aria-current="currentPage === page ? 'page' : null">
            {{ page }}
          </button>

          <button
              @click="changePage(currentPage + 1)"
              :disabled="currentPage >= totalPages"
              aria-label="Pagina successiva">
            Next
          </button>
        </nav>
      </section>
    </div>
  </main>

  <AppBottomComponent :hideVideo="true"/>
  <Footer/>
</template>

<script>
import Header from '@/components/General/AppHeader.vue';
import Footer from '@/components/General/AppFooter.vue';
import Video from '@/components/VideosPage/VideoComponent.vue';
import VideoPopupComponent from '@/components/VideosPage/VideoPopupComponent.vue';
import AppBottomComponent from '@/components/General/AppBottomComponent.vue';
import {fetchVideos, retrievePage} from '@/services/apiService';

export default {
  name: "VideosPage",
  components: {
    Header,
    Footer,
    Video,
    AppBottomComponent,
    VideoPopupComponent
  },
  data() {
    return {
      pageTitle: 'Video',
      pageSubtitle: '',
      videos: [],
      currentPage: 1,
      pageSize: 12,
      totalPages: 0,
      sortType: 'None',
      searchProfession: '',
      isPopupOpen: false,
      selectedVideoId: null
    };
  },
  async mounted() {
    await this.loadVideos();
    await this.loadPage();

    // Set title for better accessibility
    document.title = `${this.pageTitle} - Atlante delle Professioni`;
  },
  computed: {
    filteredVideos() {
      return this.videos.filter(video => {
        return (this.searchProfession === '' || (video.attributes.related_profession.data && video.attributes.related_profession.data.attributes.title.toLowerCase().includes(this.searchProfession.toLowerCase()))) &&
            (this.sortType === 'None' || video.attributes.type === this.sortType);
      });
    },
    paginatedVideos() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      return this.filteredVideos.slice(start, end);
    }
  },
  methods: {
    async loadPage() {
      try {
        const pageData = await retrievePage('Video');
        this.renderPage(pageData);
      } catch (error) {
        console.error('Error loading page:', error);
        this.error = 'Failed to load page data. Please try again later.';
      }
    },
    async renderPage(pageData) {
      this.pageTitle = pageData.attributes.main_title;
      this.pageSubtitle = pageData.attributes.main_subtitle;
    },
    async loadVideos() {
      try {
        const response = await fetchVideos();
        this.videos = response;
        this.updatePaginationAndResetPage();
      } catch (error) {
        console.error('Error loading videos:', error);
      }
    },
    updatePaginationAndResetPage() {
      this.totalPages = Math.ceil(this.filteredVideos.length / this.pageSize);
      this.currentPage = 1; // Reset to first page
    },
    changePage(page) {
      this.currentPage = page;
      // Focus on the videos container after page change for better accessibility
      this.$nextTick(() => {
        const videosContainer = document.getElementById('videos-container');
        if (videosContainer) {
          videosContainer.focus();
          // Ensure screen readers announce the page change
          const announcer = document.createElement('div');
          announcer.setAttribute('aria-live', 'polite');
          announcer.classList.add('sr-only');
          announcer.textContent = `Pagina ${page} di ${this.totalPages}`;
          document.body.appendChild(announcer);

          // Remove the announcer after a short delay
          setTimeout(() => {
            document.body.removeChild(announcer);
          }, 1000);
        }
      });
    },
    openPopup(videoId) {
      this.selectedVideoId = videoId; // storing video ID
      this.isPopupOpen = true;
    },
    changePopup(videoId) {
      this.selectedVideoId = videoId; // Handling video ID directly
    },
    closePopup() {
      this.selectedVideoId = null; // Clear selected video ID
      this.isPopupOpen = false;
    }
  }
}
</script>

<style scoped>
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

/* Skip link styling is inherited from App.vue global styles */

.container-all {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;
}

.content-videos {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 70px;
  margin-bottom: 50px;
  transition: all 0.5s ease;
}

.title-box {
  margin-left: 5px;
  margin-bottom: 100px;
}

.title-box h1 {
  font-size: 110px;
  line-height: 87px;
  letter-spacing: -2px;
  margin-top: 20px;
}

.video-page-subtitle {
  color: #323232;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  font-family: "Montserrat";
}

.controls-container {
  display: flex;
  align-items: center;
  gap: 20px;
}

.search-container {
  display: none;
  margin-bottom: 20px;
}

.search-container input[type="text"] {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  width: 220px;
  height: 25px;
  transition: all 0.5s ease;
}

.search-container input[type="text"]:focus {
  border-color: #4CAF50;
  outline: 3px solid #3BFF3B;
  outline-offset: 2px;
}

.sort-container {
  margin-bottom: 20px;
}

.sort-container select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  /* your existing styles */
  border: 1px solid #013747;
  font-size: 16px;
  background-color: transparent;
  width: 150px;
  height: 55px;
  padding: 0 20px;
  cursor: pointer;
  font-size: 11px;
  font-family: "Montserrat";
  color: #013747;
  transition: all 0.5s ease;
  text-transform: uppercase;
  letter-spacing: 1.3px;
  justify-content: center;
  align-items: center;
}

.sort-container select {
  background-image: url('@/assets/arrow_bottom.svg');
  background-repeat: no-repeat;
  background-position: right 12px center;
}

.sort-container select:hover {
  background-color: #00CC00;
}

.sort-container select:focus {
  outline: 3px solid #3BFF3B;
  outline-offset: 2px;
}

.sort-container select option {
  background-color: #fff;
  height: 70px;
  padding: 20px;
}

.sort-container select option:hover {
  background-color: #ccc;
}

.sort-container select option:checked {
  background-color: #4CAF50;
  color: white;
}

.videos-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  gap: 18px;
  min-height: 600px;
  margin-top: 50px;
  margin-bottom: 50px;
  padding-left: 10px;
  padding-right: 10px;
}

/* Add tabindex to make the container focusable for keyboard navigation */
.videos-container:focus {
  outline: none;
}

.pagination {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
  width: 100%;
  flex-wrap: wrap;
}

.pagination button {
  padding: 6px 14px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.pagination button:hover {
  background-color: #45a049;
  transform: translateY(-2px);
  color: whitesmoke;
}

.pagination button:focus {
  outline: 3px solid #3BFF3B;
  outline-offset: 2px;
  background-color: #45a049;
}

.pagination .page-number {
  padding: 4px 6px;
  background-color: transparent;
  color: #013747;
  font-size: 15px;
}

.pagination .page-number.active {
  background-color: darkgreen;
  font-weight: bold;
  color: white;
}

.pagination button:disabled {
  background-color: #ddd;
  color: #666;
  cursor: not-allowed;
}

@media (max-width: 992px) {
  .content-videos {
    width: 85%;
  }

  .videos-container {
    gap: 12px;
  }

  .pagination {
    gap: 2px;
  }
}

@media (max-width: 750px) {
  .content-videos {
    width: 85%;
  }
}
</style>