<template>
  <div class="container">
    <Header/>
    <main id="main-content" class="content">
      <div class="title-box">
        <div style="max-width: 900px">
          <h1>{{ pageTitle }}</h1>
          <p>{{ pageSubtitle }}</p>
        </div>
      </div>

      <SearchBarComponent/>
      <div class="legend-container" aria-labelledby="legend-title">
        <IconsLegendComponent/>
      </div>
      <LoadingComponent :is-visible="isLoading" style="margin-top: 100px"></LoadingComponent>

      <div v-if="!isLoading && Object.keys(professionalAreas).length === 0" role="status"
           class="status-message" aria-live="polite">
        Nessuna area professionale trovata.
      </div>

      <div class="professional-areas">
        <!-- Loop through each general area -->
        <section v-for="(categories, generalArea) in professionalAreas" :key="generalArea"
                 :aria-labelledby="`area-${generalArea.replace(/\s+/g, '-').toLowerCase()}`">
          <h2 class="general-area" :id="`area-${generalArea.replace(/\s+/g, '-').toLowerCase()}`">{{ generalArea }}</h2>
          <!-- Conditionally render this section's content if expanded -->
          <div>
            <!-- Loop through each academic category within a general area -->
            <div v-for="(areas, title) in categories" :key="title">
              <h3
                  class="academic-category"
                  :class="{ 'is-expanded': isExpanded(`${generalArea}_${title}`) }"
                  @click="toggleAcademicCategory(generalArea, title)"
                  :id="`category-${generalArea.replace(/\s+/g, '-')}-${title.replace(/\s+/g, '-')}`"
                  tabindex="0"
                  role="button"
                  :aria-expanded="isExpanded(`${generalArea}_${title}`)"
                  @keydown.enter="toggleAcademicCategory(generalArea, title)"
                  @keydown.space.prevent="toggleAcademicCategory(generalArea, title)"
              >
                {{ title }}
                <img v-if="!isExpanded(`${generalArea}_${title}`) " src="@/assets/icon_plus.svg"
                     alt="" aria-hidden="true"/>
                <img v-if="isExpanded(`${generalArea}_${title}`) " src="@/assets/icon_minus.svg"
                     alt="" aria-hidden="true"/>
              </h3>
              <!-- Conditionally render this category's content if expanded -->
              <div v-if="isExpanded(`${generalArea}_${title}`)"
                   :aria-labelledby="`category-${generalArea.replace(/\s+/g, '-')}-${title.replace(/\s+/g, '-')}`">
                <!-- Loop through each professional area within an academic category -->
                <div v-for="area in areas" :key="area.id">
                  <h4 class="professional-area"
                      :id="`area-${area.id}`"
                      @click="toggleProfessionalArea(generalArea, title, area.id)"
                      tabindex="0"
                      role="button"
                      :aria-expanded="area.expanded"
                      @keydown.enter="toggleProfessionalArea(generalArea, title, area.id)"
                      @keydown.space.prevent="toggleProfessionalArea(generalArea, title, area.id)">
                    {{ area.title }}
                    <img v-if="!area.expanded" src="@/assets/arrow_bottom.svg" alt="" aria-hidden="true"/>
                    <img v-if="area.expanded" src="@/assets/arrow_top.svg" alt="" aria-hidden="true"/>
                  </h4>
                  <!-- Conditionally render the list of related professions if the area is expanded -->
                  <ul v-if="area.expanded" :aria-labelledby="`area-${area.id}`">
                    <li v-for="profession in area.relatedProfessions" :key="profession.id">
                      <div class="profession-title">
                        <div style="width: 80%">
                          <a style="display: flex;flex-direction: row;justify-content: space-between"
                             :href="`/professioni/${profession.identifier}`"
                             aria-label="`Dettagli della professione: ${profession.title}`">
                            {{profession.title}}
                            <img style="margin-left: 12px"
                                 src="@/assets/arrow_right.svg"
                                 class="arrow-icon"
                                 alt="" aria-hidden="true"/>
                          </a>
                        </div>

                        <div style="display: flex; flex-direction: row; gap: 10px; align-items: center">
                          <InfoIcon v-if="profession.hasVideos" type="related_videos"></InfoIcon>
                          <InfoIcon v-if="profession.foglia_bianca" type="foglia_bianca"></InfoIcon>
                          <InfoIcon v-if="profession.foglia_verde" type="foglia_verde"></InfoIcon>
                        </div>
                      </div>

                      <div v-for="denomination in profession.denominazione" :key="denomination.id">
                        <div v-if="denomination.specializzazione">
                          <a :href="`/professioni/${profession.identifier}`"
                             class="specializations"
                             :aria-label="`Specializzazione ${denomination.title} della professione ${profession.title}`">
                            {{denomination.title}}
                          </a>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/General/AppHeader.vue';
import Footer from '@/components/General/AppFooter.vue';
import IconsLegendComponent from '@/components/General/IconsLegendComponent.vue';
import SearchBarComponent from '@/components/General/SearchBarComponent.vue';
import InfoIcon from '@/components/General/InfoIcon.vue';
import {fetchProfessionalAreas, retrievePage} from "@/services/apiService";
import LoadingComponent from "@/components/General/LoadingScreenComponent.vue";

export default {
  name: "ProfessionalAreasPage",
  components: {
    Header,
    Footer,
    SearchBarComponent,
    LoadingComponent,
    InfoIcon,
    IconsLegendComponent
  },
  data() {
    return {
      pageTitle: 'Aree professionali',
      pageSubtitle: '',
      professionalAreas: [],
      expandedAreas: {},
      isLoading: true,
      categoryOrder: ['Scienze matematiche, fisiche, naturali', 'Scienze della vita', 'Scienze motorie e discipline dei servizi alla persona', 'Scienze della salute', 'Scienze economiche e gestionali', 'Scienze giuridiche', 'Scienze sociali', 'Discipline letterarie, linguistiche, e artistico-espressive', 'Scienze della formazione e dell\'educazione'],
      pageMetaTitle: '',
      pageMetaDescription: '',
      error: null,
    };
  },
  async mounted() {
    try {
      await this.loadPage();
      this.setMetaTags();
      const rawData = await fetchProfessionalAreas();
      this.professionalAreas = this.transformData(rawData);
      this.isLoading = false;
    } catch (error) {
      console.error('Error fetching professional areas:', error);
      this.error = 'Si è verificato un errore durante il caricamento delle aree professionali.';
      this.isLoading = false;
    }

    // Add keyboard event listeners for accessibility
    document.addEventListener('keydown', this.handleEscapeKey);
  },
  beforeUnmount() {
    this.removeMetaTags();
    document.removeEventListener('keydown', this.handleEscapeKey);
  },
  computed: {
    pageURL() {
      return window.location.origin + window.location.pathname;
    }
  },
  methods: {
    async loadPage() {
      try {
        const pageData = await retrievePage('ProfessionalAreas');
        this.renderPage(pageData);
      } catch (error) {
        console.error('Error loading page:', error);
        this.error = 'Failed to load page data. Please try again later.';
      }
    },
    renderPage(pageData) {
      if (pageData && pageData.attributes) {
        this.pageTitle = pageData.attributes.main_title || 'Aree professionali';
        this.pageSubtitle = pageData.attributes.main_subtitle || '';
        this.generalAreaOrder = pageData.attributes.general_area;
        if (this.generalAreaOrder) {
          this.generalAreaOrder = this.generalAreaOrder.map(object => object.title);
        }
        this.pageMetaTitle = pageData.attributes.meta_title || 'Aree professionali | Atlante delle Professioni';
        this.pageMetaDescription = pageData.attributes.meta_description || '';
      }
    },
    setMetaTags() {
      document.title = this.pageMetaTitle;

      const metaTags = [
        {name: 'title', content: this.pageMetaTitle},
        {name: 'description', content: this.pageMetaDescription},
        {property: 'og:title', content: this.pageMetaTitle},
        {property: 'og:url', content: this.pageURL},
        {property: 'og:type', content: 'website'},
        {property: 'og:description', content: this.pageMetaDescription},
        {property: 'twitter:title', content: this.pageMetaTitle},
        {property: 'twitter:url', content: this.pageURL},
        {property: 'twitter:description', content: this.pageMetaDescription}
      ];

      metaTags.forEach(tagDef => {
        const tag = document.createElement('meta');
        Object.keys(tagDef).forEach(key => {
          tag.setAttribute(key, tagDef[key]);
        });
        tag.setAttribute('data-vue-meta', 'true');
        document.head.appendChild(tag);
      });
    },
    removeMetaTags() {
      const tags = document.querySelectorAll('[data-vue-meta="true"]');
      tags.forEach(tag => tag.parentNode.removeChild(tag));
    },
    transformData(data) {
      if (!data || !Array.isArray(data) || data.length === 0) {
        return {};
      }

      let transformed = {};
      data.forEach((area) => {
        try {
          if (!area.attributes ||
              !area.attributes.academic_category ||
              !area.attributes.academic_category.data ||
              !area.attributes.academic_category.data.attributes) {
            console.warn('Invalid area data structure:', area);
            return;
          }

          const generalArea = area.attributes.academic_category.data.attributes.general_area;
          const academicTitle = area.attributes.academic_category.data.attributes.title;

          if (!generalArea || !academicTitle) {
            console.warn('Missing general area or academic title:', area);
            return;
          }

          if (!transformed[generalArea]) {
            transformed[generalArea] = {};
          }
          if (!transformed[generalArea][academicTitle]) {
            transformed[generalArea][academicTitle] = [];
          }

          const relatedProfessions = [];
          if (area.attributes.related_professions &&
              area.attributes.related_professions.data &&
              Array.isArray(area.attributes.related_professions.data)) {

            area.attributes.related_professions.data.forEach(profession => {
              if (profession && profession.attributes) {
                relatedProfessions.push({
                  id: profession.id,
                  identifier: profession.attributes.identifier,
                  title: profession.attributes.title,
                  foglia_verde: profession.attributes.foglia_verde,
                  foglia_bianca: profession.attributes.foglia_bianca,
                  hasVideos: profession.attributes.hasVideos,
                  denominazione: profession.attributes.denominazione || []
                });
              }
            });
          }

          relatedProfessions.sort((a, b) => a.title.localeCompare(b.title));

          transformed[generalArea][academicTitle].push({
            id: area.id,
            title: area.attributes.title,
            relatedProfessions,
            expanded: false
          });
        } catch (error) {
          console.error(`Error processing area with id ${area?.id || 'unknown'}:`, error.message);
        }
      });

      // Alphabetically sort all levels
      transformed = this.sortObjectByKeys(transformed);

      // Custom sorting for each general area based on categoryOrder
      const sortedTransformed = {};
      this.categoryOrder.forEach(category => {
        Object.keys(transformed).forEach(generalArea => {
          if (transformed[generalArea][category]) {
            if (!sortedTransformed[generalArea]) {
              sortedTransformed[generalArea] = {};
            }
            sortedTransformed[generalArea][category] = transformed[generalArea][category];
          }
        });
      });

      // Add any remaining categories that were not included in the categoryOrder
      Object.keys(transformed).forEach(generalArea => {
        Object.keys(transformed[generalArea]).forEach(category => {
          if (!sortedTransformed[generalArea] || !sortedTransformed[generalArea][category]) {
            if (!sortedTransformed[generalArea]) {
              sortedTransformed[generalArea] = {};
            }
            sortedTransformed[generalArea][category] = transformed[generalArea][category];
          }
        });
      });

      return sortedTransformed;
    },

    sortObjectByKeys(obj) {
      if (!obj || typeof obj !== 'object') {
        return obj;
      }

      const sortedObj = {};
      const keys = Object.keys(obj).sort();

      keys.forEach(key => {
        if (obj[key] && typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
          sortedObj[key] = this.sortObjectByKeys(obj[key]); // Recursively sort nested objects
        } else if (Array.isArray(obj[key])) {
          // Safely sort arrays of objects with a title property
          sortedObj[key] = [...obj[key]]; // Create a copy to avoid mutation
          try {
            sortedObj[key].sort((a, b) => {
              if (a && b && a.title && b.title) {
                return a.title.localeCompare(b.title);
              }
              return 0;
            });
          } catch (error) {
            console.error('Error sorting array:', error);
            // Keep the original array if sorting fails
          }
        } else {
          sortedObj[key] = obj[key];
        }
      });
      return sortedObj;
    },

    toggleAcademicCategory(generalArea, academicTitle) {
      const key = `${generalArea}_${academicTitle}`;
      this.expandedAreas[key] = !this.expandedAreas[key];

      // Announce the change for screen readers
      this.announceStateChange(academicTitle, this.expandedAreas[key]);
    },

    toggleProfessionalArea(generalArea, academicTitle, areaId) {
      if (!this.professionalAreas[generalArea] ||
          !this.professionalAreas[generalArea][academicTitle]) {
        return;
      }

      const area = this.professionalAreas[generalArea][academicTitle].find(a => a.id === areaId);
      if (area) {
        area.expanded = !area.expanded;

        // Announce the change for screen readers
        this.announceStateChange(area.title, area.expanded);
      }
    },

    isExpanded(key) {
      return !!this.expandedAreas[key];
    },

    handleEscapeKey(event) {
      // Close any expanded areas when Escape key is pressed
      if (event.key === 'Escape') {
        const expandedKeys = Object.keys(this.expandedAreas).filter(key => this.expandedAreas[key]);
        if (expandedKeys.length > 0) {
          // Close the most recently expanded area
          this.expandedAreas[expandedKeys[expandedKeys.length - 1]] = false;
          event.preventDefault();
        }
      }
    },

    announceStateChange(title, isExpanded) {
      // Create a live region announcement for screen readers
      const announcement = document.createElement('div');
      announcement.setAttribute('aria-live', 'polite');
      announcement.setAttribute('class', 'sr-only');
      announcement.textContent = `${title} ${isExpanded ? 'espanso' : 'chiuso'}`;

      document.body.appendChild(announcement);

      // Remove it after it's been announced
      setTimeout(() => {
        document.body.removeChild(announcement);
      }, 1000);
    }
  }
}
</script>

<style scoped>
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}

.professional-areas {
  margin-top: 100px;
  width: 60%;
  max-width: 1000px;
}

.status-message {
  margin-top: 2rem;
  padding: 1rem;
  text-align: center;
  font-size: 1.2rem;
  color: #666;
}

.general-area {
  font-size: 45px;
  color: white;
  font-family: "IBMPlexSans";
}

.academic-category {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 30px;
  padding: 15px 0 30px 0;
  border-bottom: 1px solid white;
  color: #013747;
  transition: border-color 0.3s;
  font-family: "IBMPlexSans";
}

/* New class for when the category is expanded */
.academic-category.is-expanded {
  border-bottom: 1px solid #013747;
}

/* Focus styles for interactive elements */
.academic-category:focus,
.professional-area:focus {
  outline: 3px solid #3BFF3B;
  outline-offset: 3px;
}

.professional-area {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px 0 20px 25px;
  font-size: 25px;
  margin-top: 15px;
  margin-bottom: 15px;
  border-bottom: 1px dotted #013747;
  color: #013747;
  font-family: "IBMPlexSans";
}

.profession-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 80px;
  font-weight: bold;
  font-size: 20px;
  transition: all 0.1s ease;
  color: #013747;
  border-bottom: 1px dotted white;
  margin-bottom: 5px;
  font-family: "IBMPlexSans";
}

.profession-title:last-child {
  border-bottom: none;
  margin-bottom: 25px;
}

.profession-title a {
  text-decoration: none;
  color: inherit;
  font-size: 20px;
  letter-spacing: -0.4px;
  line-height: 24px;
  font-family: "IBMPlexSans";
}

.profession-title a:focus {
  outline: 3px solid #3BFF3B;
  outline-offset: 3px;
}

.profession-title:hover {
  color: white;
  transform: translateX(5px);
}

.specializations {
  margin-left: 85px;
  font-weight: normal;
  font-size: 16px;
  transition: all 0.1s ease;
  color: #013747;
  margin-bottom: 3px;
  letter-spacing: -0.4px;
  line-height: 24px;
  text-decoration: none;
  font-family: "IBMPlexSans";
  display: inline-block;
  padding: 4px 0;
}

.specializations:focus {
  outline: 3px solid #3BFF3B;
  outline-offset: 3px;
}

.specializations:hover {
  color: white;
  transform: translateX(5px);
}

hr {
  border: none;
  height: 1px;
  background-color: #ddd;
  margin: 10px 0;
}

ul {
  list-style: none;
  padding-left: 0;
}

ul li {
  padding: 5px 0;
}

h3, h4 {
  cursor: pointer;
  user-select: none;
}

.title-box h1 {
  font-size: 7em;
}

.legend-container {
  display: none;
}

.expand-enter-active, .expand-leave-active {
  transition: max-height 0.5s ease-in-out;
}

.expand-enter, .expand-leave-to /* .expand-leave-active in <2.1.8 */
{
  max-height: 0;
}

@media (max-width: 992px) {
  .title-box h1 {
    font-size: 3.1em;
    width: 75%;
  }

  .professional-areas {
    width: 80%;
    margin-top: 5px;
  }

  .general-area {
    font-size: 32px;
    color: hsl(0deg, 0%, 100%);
    letter-spacing: -0.63px;
    line-height: 33px;
  }

  .academic-category {
    font-size: 20px;
    color: #013747;
    letter-spacing: -0.4px;
    line-height: 23px;
    gap: 20px;
  }

  .professional-area {
    font-size: 18px;
    letter-spacing: -0.5px;
    line-height: 21px;
    gap: 20px;
  }

  .profession-title {
    margin-left: 40px;
  }

  .profession-title a {
    font-size: 15px;
    letter-spacing: -0.5px;
    line-height: 22px;
    display: block;
  }

  .arrow-icon {
    display: none;
  }

  .legend-container {
    display: block;
  }
}
</style>