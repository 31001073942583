<template>
  <header class="header" role="banner">
    <div class="header-container">
      <a href="/" aria-label="Homepage Atlante delle Professioni">
        <img src="@/assets/logo-atlante.svg" alt="Atlante delle Professioni Logo" class="logo logo-atlante">
      </a>
      <a href="https://www.unito.it/" target="_blank" rel="noopener" aria-label="Sito dell'Università di Torino (si apre in una nuova finestra)">
        <img src="@/assets/logo-unito.svg" alt="Università di Torino Logo" class="logo logo-unito">
      </a>

      <div class="header-controls">
        <!-- Conditionally render SearchButtonComponent based on currentPage -->
        <div v-if="showSearchButton" class="srch-btn-container">
          <SearchButtonComponent />
        </div>
        <button
            class="nav-toggle-btn"
            @click="toggleNav"
            aria-label="Apri il menu di navigazione"
            :aria-expanded="isNavOpen ? 'true' : 'false'"
            aria-controls="navigation-menu"
        >
          <img src="@/assets/nav-icon.png" alt="" aria-hidden="true"/>
          <span class="sr-only">Menu</span>
        </button>
      </div>
    </div>
  </header>
  <AppNavigationMenu
      id="navigation-menu"
      :isOpen="isNavOpen"
      @close-nav="toggleNav"
  />
</template>


<script>
// Import the navigation menu component
import AppNavigationMenu from '@/components/General/AppNavigationMenu.vue';
import SearchButtonComponent from '@/components/General/SearchButtonComponent.vue';

export default {
  name: 'AppHeader',
  components: {
    AppNavigationMenu,
    SearchButtonComponent
  },
  data() {
    return {
      isNavOpen: false,
    };
  },
  props: {
    showSearchButton: {
      type: Boolean,
      required: false,
    },
  },
  methods: {
    toggleNav() {
      this.isNavOpen = !this.isNavOpen;

      // If we're opening the menu, set focus to the close button after a short delay
      // to allow for the transition
      if (this.isNavOpen) {
        setTimeout(() => {
          const closeBtn = document.querySelector('.nav-menu .close-btn');
          if (closeBtn) closeBtn.focus();
        }, 300);
      }
    },
  },
};
</script>


<style scoped>
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  /* background-color: rgba(1, 55, 71,0.02); */
}

.header-container {
  width: 98%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 10px;
  padding: 2rem 0% 3rem 0%;
}

.header-controls {
  display: flex;
  flex-direction: row;
  gap: 30px;
  align-items: center;
}

.logo {
  height: auto;
  width: auto; /* Ensure the logo maintains its aspect ratio */
  transition: height 0.5s ease;
}

/* Specific logo adjustments */
.logo-atlante {
  height: 110px; /* Default size for large screens */
  width: auto;
}

.logo-unito {
  height: 90px; /* Default size for large screens */
  width: auto;
}

.nav-toggle-btn {
  background: transparent;
  border: none;
  padding: 10px; /* Increased from 0 for better touch target */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  min-width: 44px; /* Ensure minimum touch target size */
  min-height: 44px; /* Ensure minimum touch target size */
}

/* Focus style for keyboard navigation */
.nav-toggle-btn:focus,
.header-container a:focus {
  outline: 3px solid #3BFF3B;
  outline-offset: 3px;
}

.nav-toggle-btn img {
  height: 24px; /* Or the size of your preference */
  width: 24px; /* Maintain the aspect ratio */
}

.srch-btn-container {
  width: 200px;
  background-color: #3BFF3B;
}

@media (max-width: 1600px) {
  .header-container {
    width: 98%;
  }
}

@media (max-width: 1300px) {
  .header-container {
    width: 90%;
  }

  .srch-btn-container {
    width: 50px;
    margin-left: 5px;
  }
}

@media (max-width: 992px) {
  .srch-btn-container {
    width: 50px;
    margin-left: 0px;
  }

  .logo-atlante,
  .logo-unito {
    height: 50px;
  }
}

@media (max-width: 768px) {
  .header-container {
    padding: 0.5rem 10%;
    width: 95%;
  }

  .nav-toggle-btn {
    display: flex;
  }
}

/* small devices (portrait phones, less than 600px) */
@media (max-width: 600px) {
  .header-container {
    padding: 0.5rem 5%;
    width: 95%;
  }
}
</style>