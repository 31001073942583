<template>
  <div class="search-bar-container" role="search">
    <form class="search-bar" @submit.prevent="openPopup">
      <label for="search-input" class="sr-only">Cerca una professione o un'area professionale</label>
      <input
          id="search-input"
          type="text"
          placeholder="Cerca..."
          class="search-input"
          @click="openPopup"
          aria-describedby="search-description"
          readonly
      />
      <span id="search-description" class="sr-only">
        Clicca sul campo di ricerca o sul pulsante Cerca per aprire la finestra di ricerca
      </span>

      <button type="submit" class="search-bar-button" aria-label="Avvia ricerca">CERCA</button>
    </form>
  </div>
  <SearchPopupComponent
      :isVisible="isPopupOpen"
      @close-popup="closePopup"
  />
</template>

<script>
import {fetchProfessions} from "@/services/apiService";
import SearchPopupComponent from '@/components/General/SearchPopupComponent.vue';

export default {
  name: 'SearchBarComponent',
  components: {
    SearchPopupComponent
  },
  data() {
    return {
      isPopupOpen: false,
      professions: [],
    };
  },
  methods: {
    openPopup() {
      this.isPopupOpen = true;
    },
    closePopup() {
      this.isPopupOpen = false;
      // Return focus to the search input when popup closes
      this.$nextTick(() => {
        document.getElementById('search-input').focus();
      });
    }
  },
  // Optionally, fetch professions list on component mount
  async mounted() {
    try {
      this.professions = await fetchProfessions();
    } catch (error) {
      console.error('Error fetching professions:', error);
    }
  },
};
</script>

<style scoped>
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.search-bar-container {
  width: 100%;
  display: flex;
  justify-content: center; /* Center align the search bar */
  align-items: center;
  transition: all 0.3s ease;
  z-index: 20;
}

.search-bar {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly; /* Space between items */
  width: 75%;
  max-width: 1000px;
  height: 85px;
  background-color: white;
  box-shadow: 0 22px 48px 0 rgba(0, 0, 0, 0.21);
  transition: box-shadow 0.3s ease; /* Smooth transition for shadow */
}


.search-input {
  width: 90%;
  height: 100%;
  border: none;
  padding: 0 30px;
  background-color: white;
  font-size: 22px;
  font-family: "Montserrat", sans-serif;
  color: #333333;
  flex-grow: 2;
  cursor: pointer;
}

.search-input:focus {
  outline: 3px solid #013747;
  outline-offset: -3px;
}

.search-bar:hover {
  box-shadow: 0px 12px 22px rgba(0, 0, 0, 0.3); /* Enhanced shadow on hover */
}

.search-bar-button {
  height: 70%;
  width: 180px;
  margin-right: 10px;
  font-weight: bold;
  font-family: "Montserrat";
  color: white;
  background-color: #013747;
  border: none;
  cursor: pointer;
  padding: 10px 20px;
}

.search-bar-button:focus {
  outline: 3px solid #3BFF3B;
  outline-offset: 3px;
}

.search-bar-button:hover {
  background-color: #024e62;
}


/* Medium devices (landscape phones, less than 768px) */
@media (max-width: 768px) {
  .search-bar {
    max-width: 600px;
  }
}

/* small devices (portrait phones, less than 600px) */
@media (max-width: 600px) {
  .search-bar {
    max-width: 400px;
    width: 90%;
  }

  .search-bar-button {
    width: 100px;
  }
}
</style>