<template>
  <section class="search-container" aria-labelledby="search-section-title">
    <div class="title-subtitle">
      <h2 id="search-section-title" class="title">Ricerca per tema</h2>
      <p class="subtitle">Cerca la professione che ti interessa per elenco alfabetico, per appartenenza a un'area
        professionale specifica o per corso di laurea.</p>
    </div>
    <div class="search-options">
      <div class="search-option">
        <h3>Figure professionali</h3>
        <p>Ricerca navigando per elenco alfabetico.</p>
        <a href="/professioni" class="search-button" aria-label="Ricerca figure professionali">
          RICERCA <img src="@/assets/arrow-green.svg" alt="" aria-hidden="true"/>
        </a>
      </div>
      <div class="search-option">
        <h3>Aree professionali</h3>
        <p>Ricerca per appartenenza alle aree professionali.</p>
        <a href="/aree-professionali" class="search-button" aria-label="Ricerca aree professionali">
          RICERCA <img src="@/assets/arrow-green.svg" alt="" aria-hidden="true"/>
        </a>
      </div>
      <div class="search-option">
        <h3>Corsi di laurea</h3>
        <p>Ricerca per appartenenza ai Corsi di Laurea.</p>
        <a href="/corsi-di-laurea" class="search-button" aria-label="Ricerca corsi di laurea">
          RICERCA <img src="@/assets/arrow-green.svg" alt="" aria-hidden="true"/>
        </a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'SearchByTopicComponent'
}
</script>

<style scoped>
.search-container {
  background-color: rgba(1, 55, 71, 1); /* Dark background color */
  color: #FFFFFF; /* White text color */
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  padding: 100px 50px;
  gap: 30px;
  z-index: 4;
}

.title-subtitle {
  width: 55%;
  margin-bottom: 50px;
}

.title {
  font-size: 6rem; /* Large title font size */
  font-weight: bold;
  margin-bottom: 0;
  color: lime;
  width: 40%;
  line-height: 1.1; /* Improved from 90% for better readability */
  margin-bottom: 30px;
}

.subtitle {
  font-size: 1.2rem;
  max-width: 800px; /* Maximum width for better readability */
  margin-top: 0;
  width: 70%;
}

.search-options {
  display: flex;
  flex-direction: row;
  gap: 80px;
  justify-content: center;
  width: 40%;
}

.search-option {
  padding: 20px;
  position: relative;
  max-width: 250px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;
}

.search-option::after {
  content: '';
  position: absolute;
  top: 0;
  width: 90%;
  height: 2px; /* The thickness of your border */
  background: linear-gradient(rgba(50, 205, 50, 0), rgba(50, 205, 50, 0.6), rgba(50, 205, 50, 1) 80%, rgba(50, 205, 50, 0.5), rgba(50, 205, 50, 0));
  border-radius: 10px 10px 0 0; /* Only the top corners rounded */
}

.search-option h3 {
  font-size: 2rem;
  margin-top: 0;
  margin-bottom: 20px;
  width: 180px;
}

.search-option p {
  margin-top: 0;
  margin-bottom: 20px;
  width: 250px;
  color: #DDDDDD;
  font-size: 18px;
}

.search-button {
  border: none;
  padding: 10px 30px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 11px;
  text-transform: uppercase;
  width: 100%;
  text-decoration: none;
  font-weight: bold;
  text-align: left;
  color: white;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

/* Focus style for keyboard navigation */
.search-button:focus {
  outline: 3px solid #3BFF3B;
  outline-offset: 3px;
}

.search-button img {
  transition: transform 0.3s ease; /* Smooth transition for movement */
}

.search-button:hover img {
  transform: translateX(8px); /* Moves the arrow 5px to the right */
}

.search-button:focus img {
  transform: translateX(8px); /* Same transformation on focus for keyboard users */
}

@media (max-width: 1800px) {
  .title-subtitle {
    width: 65%;
  }
}

@media (max-width: 992px) {
  .search-container {
    text-align: left;
    padding: 100px 30px 20px 30px;
    gap: 0px;
  }

  .title {
    font-size: 55px;
    line-height: 1.2; /* Improved from 44px for better readability */
    letter-spacing: -1.5px;
    width: 80%;
  }

  .subtitle {
    font-size: 16px;
  }

  .search-options {
    flex-direction: column;
    width: 100%;
    gap: 20px
  }

  .search-option {
    padding: 20px 0px;
    max-width: 1200px;
    width: 100%;
  }

  .search-option::after {
    width: 100%;
  }

  .title-subtitle {
    width: 100%;
    margin-bottom: 50px;
  }

  .search-option p{
    font-size: 16px;
  }

  .search-button {
    padding: 20px 0px;
  }
}
</style>