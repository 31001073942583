<template>
  <div class="search-button-container">
    <button
        class="search-p-button"
        @click="openPopup"
        aria-label="Apri la ricerca"
    >
      <img src="@/assets/icon_search.svg" alt="" aria-hidden="true"/>
    </button>
  </div>
  <SearchPopupComponent
      :isVisible="isPopupOpen"
      @close-popup="closePopup"
  />
</template>

<script>
import {fetchProfessions} from "@/services/apiService";
import SearchPopupComponent from '@/components/General/SearchPopupComponent.vue';

export default {
  name: 'SearchButtonComponent',
  components: {
    SearchPopupComponent
  },
  data() {
    return {
      isPopupOpen: false,
      professions: [],
    };
  },
  methods: {
    openPopup() {
      this.isPopupOpen = true;
    },
    closePopup() {
      this.isPopupOpen = false;
      // Return focus to the search button when popup closes
      this.$nextTick(() => {
        this.$el.querySelector('.search-p-button').focus();
      });
    }
  },
  // Pre-fetch professions list on component mount for better performance
  async mounted() {
    try {
      this.professions = await fetchProfessions();
    } catch (error) {
      console.error('Error fetching professions:', error);
    }
  },
};
</script>

<style scoped>
.search-button-container {
  display: flex;
  width: 100%;
  height: 45px;
  background-color: transparent;
  justify-content: center;
  padding: 0;
  flex: inherit;
  cursor: pointer;
}

.search-p-button {
  width: 100%;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 8px;
  min-width: 44px; /* Minimum touch target size */
  min-height: 44px; /* Minimum touch target size */
}

.search-p-button:focus {
  outline: 3px solid #013747;
  outline-offset: 2px;
}

.search-p-button img {
  width: 24px;
  height: 24px;
}
</style>