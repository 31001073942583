<template>
  <div class="legenda" role="region" aria-labelledby="legend-title">
    <h3 id="legend-title" class="sr-only">Legenda delle icone</h3>
    <div class="info-line">
      <img src="@/assets/icon_leaf_empty.svg" alt="" aria-hidden="true">
      <p><strong>Professione rinverdita</strong>: la formazione include insegnamenti sulla sostenibilità ambientale</p>
    </div>
    <div class="info-line">
      <img src="@/assets/icon_leaf_green.svg" alt="" aria-hidden="true">
      <p><strong>Professione verde</strong>: ha come obiettivo principale la sostenibilità ambientale</p>
    </div>
    <div class="info-line">
      <img src="@/assets/icon_screen.svg" alt="" aria-hidden="true">
      <p><strong>Video disponibile</strong>: la professione è arricchita da video di approfondimento</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "IconsLegendComponent"
}
</script>

<style scoped>
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.legenda {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: whitesmoke;
  padding: 20px;
  margin: 30px;
  border-radius: 4px;
}

.legenda p {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  font-size: 0.75em;
  margin: 0;
}

.info-line {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.info-line img {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}

@media (max-width: 768px) {
  .legenda {
    margin: 15px;
    padding: 15px;
  }

  .info-line {
    gap: 10px;
  }
}
</style>