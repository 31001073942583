<template>
  <section class="profession-slider-component" aria-labelledby="profession-slider-heading">
    <h2 id="profession-slider-heading" class="sr-only">Professioni in evidenza</h2>
    <div v-if="error" role="alert">{{ error }}</div>
    <div v-else class="slider" role="region" aria-roledescription="carousel" aria-label="Slider delle professioni">
      <div v-for="(profession, index) in professions" :key="profession.id" class="slide"
           :class="{'active': currentSlideIndex === index}"
           role="group"
           :aria-roledescription="'slide'"
           :aria-label="`slide ${index + 1} of ${professions.length}`"
           :aria-hidden="currentSlideIndex !== index">

        <div class="intro-block">
          <h3 class="little-header">PROFESSIONI</h3>
          <a class="profession-title" :href="`/professioni/${profession.attributes.identifier}`"
             style="text-decoration: none">
            <span class="profession-title">{{ profession.attributes.title }}</span>
          </a>
          <div class="profession-description" v-html="profession.attributes.identity.description"></div>
          <div style="margin-left: 100px">
            <button @click="prevSlide" class="slider-button prev" aria-label="Professione precedente">
              <img style="width: 30px; transform: scaleX(-1);" src="@/assets/slider_arrow.svg" alt="" aria-hidden="true"/>
            </button>
            <button @click="nextSlide" class="slider-button next" aria-label="Professione successiva">
              <img style="width: 30px;" src="@/assets/slider_arrow.svg" alt="" aria-hidden="true"/>
            </button>
          </div>
        </div>

        <img v-if="profession.attributes && profession.attributes.identity.image.data"
             :src="getImageFileUrl(profession.attributes.identity.image.data.attributes.url)"
             :alt="`Immagine rappresentativa della professione: ${profession.attributes.title}`"
             class="profession-image-slider"/>

        <div class="slider-pagination" role="tablist" aria-label="Seleziona professione">
          <button v-for="index in professions.length"
                  :key="index"
                  class="slider-page"
                  :class="{'active': currentSlideIndex === index-1}"
                  @click="setSlide(index-1)"
                  role="tab"
                  :aria-selected="currentSlideIndex === index-1 ? 'true' : 'false'"
                  :aria-label="`Seleziona professione ${index}`"
                  :tabindex="currentSlideIndex === index-1 ? 0 : -1">
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {retrievePage} from "@/services/apiService";

export default {
  name: "ProfessionSliderComponent",
  data() {
    return {
      professions: [],
      currentSlideIndex: 0,
      error: null,
    };
  },
  mounted() {
    this.loadPage();
    // Add keyboard navigation for the slider
    document.addEventListener('keydown', this.handleKeyboardNavigation);
  },
  beforeUnmount() {
    // Clean up event listener
    document.removeEventListener('keydown', this.handleKeyboardNavigation);
  },
  methods: {
    async loadPage() {
      try {
        const pageData = await retrievePage('Home');

        this.professions = pageData.attributes.profession_previews.data.map(profession => ({
          ...profession,
          attributes: {
            ...profession.attributes,
            identity: {
              ...profession.attributes.identity,
              description: this.truncateText(profession.attributes.identity.description, 210) // Assuming 250 characters as max
            }
          }
        }));
        console.log(this.professions);
      } catch (error) {
        console.error('Error loading page:', error);
        this.error = 'Failed to load page data. Please try again later.';
      }
    },
    prevSlide() {
      if (this.currentSlideIndex === 0) {
        this.currentSlideIndex = this.professions.length - 1;
      } else {
        this.currentSlideIndex--;
      }
      this.announceSlideChange();
    },
    nextSlide() {
      if (this.currentSlideIndex === this.professions.length - 1) {
        this.currentSlideIndex = 0;
      } else {
        this.currentSlideIndex++;
      }
      this.announceSlideChange();
    },
    getImageFileUrl(url) {
      const imageLink = `${process.env.VUE_APP_BASE_BACK_URL}${url}`;
      return imageLink;
    },
    setSlide(index) {
      this.currentSlideIndex = index;
      this.announceSlideChange();
    },
    truncateText(text, maxLength) {
      if (text.length > maxLength) {
        return text.substring(0, maxLength - 3) + '...';
      }
      return text;
    },
    handleKeyboardNavigation(event) {
      // Only handle keyboard events when slider is in view
      const sliderElement = document.querySelector('.profession-slider-component');
      if (!sliderElement) return;

      // Check if element is in viewport
      const rect = sliderElement.getBoundingClientRect();
      const isInViewport = rect.top >= 0 && rect.bottom <= window.innerHeight;

      if (!isInViewport) return;

      if (event.key === 'ArrowLeft') {
        this.prevSlide();
        event.preventDefault();
      } else if (event.key === 'ArrowRight') {
        this.nextSlide();
        event.preventDefault();
      }
    },
    announceSlideChange() {
      // For screen readers - in a real implementation you might want to use
      // an aria-live region to announce the slide change
    }
  }
}
</script>


<style scoped>
/* Screen reader only class */
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.profession-slider-component .slider {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 644px;
}

.profession-slider-component .slide {
  display: none;
  position: relative;
}

.profession-slider-component .slide.active {
  width: 100%;
  display: block;
}

.intro-block {
  position: absolute;
  box-shadow: 0 22px 44px 0 rgba(0, 0, 0, 0.27);
  background-color: #3BFF3B;
  width: 280px;
  height: 440px;
  bottom: inherit;
  padding: 40px 60px;
  left: 16vw;
  right: auto;
  z-index: 100;
  display: flex; /* Use flexbox */
  flex-direction: column; /* Align items vertically */
  align-items: flex-end;
  top: -50px;
}

.profession-image-slider {
  width: auto;
  min-width: 100%;
  height: 1000px;
  object-fit: cover;
  aspect-ratio: auto 1600 / 800;
  z-index: 0;
}

.profession-description {
  overflow: hidden; /* Hide overflow when description is collapsed */
  max-height: 300px;
  position: relative;
  width: 100%;
  transition: all 0.5s ease;
  margin-bottom: 40px;
}

.slider-button {
  border: none;
  padding: 8px 12px; /* Increased padding for better touch target */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  background-color: transparent;
}

/* Focus styles for keyboard navigation */
.slider-button:focus,
.slider-page:focus,
.profession-title:focus {
  outline: 3px solid #013747;
  outline-offset: 3px;
}

.little-header {
  color: darkslategray;
  width: 100%;
  font-family: "Montserrat";
  font-size: 11px;
  color: #013747;
  letter-spacing: 1.3px;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.profession-title {
  width: 100%;
  font-size: 36px;
  line-height: 35px;
  margin-top: 5px;
  color: #323232;
  text-align: left;
}

.slider-pagination {
  position: absolute;
  top: 580px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  z-index: 20;
}

.slider-page {
  width: 180px; /* Tiny rectangle width */
  height: 5px; /* Tiny rectangle height */
  background-color: rgba(204, 204, 204, 0.5); /* Default color */
  margin: 0 5px; /* Spacing between buttons */
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth transition for background color */
  border: none;
  cursor: pointer;
}

.slider-page.active {
  background-color: #3BFF3B; /* Green color when active */
}

@media (max-width: 992px) {
  .intro-block {
    position: absolute;
    width: 250px;
    height: 440px;
    bottom: inherit;
    padding: 40px 40px;
    left: 6vw;
    top: 60px;
    align-items: flex-start;
  }


  .slider-page {
    width: 25px; /* Tiny rectangle width */
    height: 7px; /* Tiny rectangle height */
  }

  .profession-title {
    font-family: "IBMPlexSans", sans-serif;
    font-size: 32px;
    color: #013747;
    letter-spacing: -0.63px;
    line-height: 33px;
  }

  .profession-description {
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 0.3px;
    font-weight: 400;
    font-family: "Montserrat", sans-serif;
  }
}
</style>