<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
  mounted() {
    // Add class to body to detect if user is using mouse or keyboard
    document.body.addEventListener('mousedown', () => {
      document.body.classList.add('using-mouse');
    });

    document.body.addEventListener('keydown', (e) => {
      // Only remove the class if it's a Tab key
      if (e.key === 'Tab') {
        document.body.classList.remove('using-mouse');
      }
    });
  },
  beforeUnmount() {
    // Clean up event listeners
    document.body.removeEventListener('mousedown', () => {});
    document.body.removeEventListener('keydown', () => {});
  }
};
</script>

<style>
#app {
  background-color: #e1e2e2;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scrolling at the body level */
}

/* Hide focus outline when using mouse */
body.using-mouse *:focus {
  outline: none !important;
  box-shadow: none !important;
}

/* Show focus outline only when using keyboard - using a distinctive purple color */
*:focus {
  outline: 3px solid #9B30FF !important; /* Bright purple for high visibility */
  outline-offset: 3px !important;
  box-shadow: 0 0 0 1px white !important; /* Tiny white outline */
}


/* Skip to content link style */
.skip-link {
  position: absolute;
  top: -45px;
  left: 0;
  background: #9B30FF; /* Changed to match focus outline */
  color: #FFFFFF; /* White text on purple background for contrast */
  padding: 8px;
  z-index: 9999; /* Ensure it's above everything */
  transition: top 0.3s;
  text-decoration: none;
  font-weight: bold;
}

.skip-link:focus {
  top: 0;
}

/* Screen reader only class */
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

@font-face {
  font-family: 'IBMPlexSans';
  font-style: normal;
  src: local('Custom Font'),
  url('@/assets/fonts/IBMPlexSans-SemiBold.61f54ef0.woff2') format('woff2')
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  src: local('Custom Font'),
  url('@/assets/fonts/Montserrat-Regular.93dee51e.woff2') format('woff2')
}

@font-face {
  font-family: 'MontserratSemiBold';
  src: local('Custom Font'),
  url('@/assets/fonts/Montserrat-SemiBold.803c1a37.woff2') format('woff2')
}


h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'IBMPlexSans';
}

h1 {
  font-size: 35px;
}

p, tr, td, li, a {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  line-height: 25px;
  -webkit-font-smoothing: antialiased;
}

a {
  color: #013747;
}
</style>