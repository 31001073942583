<template>
  <section class="bottom-component" aria-labelledby="bottom-section-heading">
    <h2 id="bottom-section-heading" class="sr-only">Risorse Aggiuntive</h2>
    <div class="video-section" v-if="!hideVideo">
      <div class="video-section-text">
        <div>
          <h3 id="video-section-title">{{ video_title }}</h3>
          <p id="video-section-subtitle">{{ video_subtitle }}</p>
        </div>
        <button
            class="bc-button"
            @click="watchVideo"
            aria-labelledby="video-section-title video-section-subtitle"
            aria-describedby="watch-videos-desc"
        >
          <b>GUARDA I VIDEO</b>
        </button>
        <span id="watch-videos-desc" class="sr-only">Clicca per visualizzare i video informativi sulle professioni</span>
      </div>
    </div>
    <div class="statistics-section" :class="{ 'full-width': hideVideo }">
      <div class="statistics-section-text">
        <div>
          <h3 id="statistics-section-title">{{ statistics_title }}</h3>
          <p id="statistics-section-subtitle">{{ statistics_subtitle }}</p>
        </div>
        <button
            class="bc-button"
            @click="viewStatistics"
            aria-labelledby="statistics-section-title statistics-section-subtitle"
            aria-describedby="view-statistics-desc"
        >
          <b>GUARDA LE STATISTICHE</b>
        </button>
        <span id="view-statistics-desc" class="sr-only">Clicca per visualizzare le statistiche sull'occupazione dei laureati. Si apre in una nuova finestra.</span>
      </div>
    </div>
  </section>
</template>

<script>
import {retrievePage} from "@/services/apiService";

export default {
  name: "AppBottomComponent",
  props: {
    hideVideo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      video_title: 'Video',
      video_subtitle: '',
      statistics_title: 'Statistiche',
      statistics_subtitle: '',
      error: null,
    };
  },
  mounted() {
    this.loadPage();
  },
  methods: {
    watchVideo() {
      this.$router.push('/video');
    },
    viewStatistics() {
      window.open("https://statistiche.atlantedelleprofessioni.it/", "_blank", "noopener,noreferrer");
    },
    async loadPage() {
      try {
        const pageData = await retrievePage('Home');
        this.renderPage(pageData);
      } catch (error) {
        console.error('Error loading page:', error);
        this.error = 'Failed to load page data. Please try again later.';
      }
    },
    renderPage(pageData) {
      if (pageData && pageData.attributes && pageData.attributes.bottom_mini_blocks) {
        if (pageData.attributes.bottom_mini_blocks.length > 0) {
          this.video_title = pageData.attributes.bottom_mini_blocks[0].title || 'Video';
          this.video_subtitle = pageData.attributes.bottom_mini_blocks[0].subtitle || '';
        }

        if (pageData.attributes.bottom_mini_blocks.length > 1) {
          this.statistics_title = pageData.attributes.bottom_mini_blocks[1].title || 'Statistiche';
          this.statistics_subtitle = pageData.attributes.bottom_mini_blocks[1].subtitle || '';
        }
      }
    }
  }
}
</script>

<style scoped>
/* Screen reader only class */
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.bottom-component {
  display: flex;
  justify-content: space-evenly;
  background-color: #003366; /* Example color */
  color: white;
  height: auto; /* Changed to auto for responsiveness */
  z-index: 10;
}

.bottom-component h3 {
  font-size: 24px;
}

.bottom-component p {
  font-size: 16px;
}

.bottom-component button {
  background-color: transparent;
  border: 1px solid limegreen;
  color: white;
  font-family: "Montserrat";
  padding: 10px 20px;
  cursor: pointer;
  min-height: 44px; /* Minimum touch target size */
}

.bottom-component button:focus {
  outline: 3px solid #3BFF3B;
  outline-offset: 2px;
}

.bottom-component button:hover {
  background-color: rgba(59, 255, 59, 0.2);
}

.video-section, .statistics-section {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 30px 50px 50px 50px;
}

.video-section h3, .statistics-section h3 {
  font-size: 48px;
}

.video-section {
  background-image: url("@/assets/video_bg.82cb0903.jpg");
  background-size: cover;
  background-position: center;
  align-items: flex-end;
}

.video-section-text, .statistics-section-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 60%;
  height: 300px;
  margin-bottom: 60px;
}

.statistics-section {
  align-items: flex-start;
  background-image: url("@/assets/statistiche_bg.44bf4393.jpg");
  background-size: cover;
  background-position: center;
}

.bc-button {
  width: 55%;
  height: 50px;
  font-size: 12px;
}

.statistics-section.full-width {
  width: 100%;
  padding-left: 250px;
}

@media (max-width: 992px) {
  .bottom-component {
    flex-direction: column;
    align-items: center;
  }

  .video-section, .statistics-section {
    width: 100%;
    padding: 30px 0;
    align-items: center;
  }

  .video-section-text, .statistics-section-text {
    width: 80%;
    align-items: center;
    margin-bottom: 30px;
  }

  .video-section h3, .statistics-section h3 {
    font-size: 36px;
    width: 100%;
    text-align: center;
  }

  .video-section p, .statistics-section p {
    text-align: center;
  }

  .bottom-component p {
    font-size: 14px;
  }

  .bottom-component button {
    width: 90%;
  }

  .statistics-section.full-width {
    width: 100%;
    padding-left: 0px;
  }
}
</style>