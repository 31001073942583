<template>
  <nav class="nav-menu" :class="{ 'open': isOpen }" :aria-hidden="!isOpen">
    <div v-if="isOpen" ref="menuContent" class="menu-content">
      <!-- Add a first focusable element that captures backward tab and redirects to last element -->
      <span tabindex="0" ref="firstFocusableElement" @keydown="handleFirstElementKeydown" class="sr-only">
        Inizio del menu. Premere Maiusc+Tab per andare all'ultimo elemento.
      </span>

      <button class="close-btn" @click="closeNav" aria-label="Chiudi il menu di navigazione" ref="closeButton">
        <img src="@/assets/close-btn-icon.png" alt="" aria-hidden="true"/>
      </button>
      <div class="srch-comp" role="search">
        <img src="@/assets/icon_search_green.svg" alt="" aria-hidden="true"/>
        <input
            type="text"
            placeholder="cerca"
            class="search-input-nav"
            @click="openPopup"
            @keydown.enter="openPopup"
            aria-label="Cerca nel sito"
            ref="searchInput"
        />
      </div>

      <ul>
        <li v-for="(item) in navbarElements" :key="item.title">
          <a
              :href="item.link"
              :target="isExternalLink(item.link) ? '_blank' : '_self'"
              :aria-label="isExternalLink(item.link) ? `${item.title} (si apre in una nuova finestra)` : item.title"
              ref="navItems"
          >
            {{ item.title }}
          </a>
        </li>
      </ul>

      <!-- Last focusable element that loops back to first element -->
      <span tabindex="0" ref="lastFocusableElement" @keydown="handleLastElementKeydown" class="sr-only">
        Fine del menu. Premere Tab per tornare all'inizio del menu.
      </span>
    </div>
  </nav>
  <SearchPopupComponent
      :isVisible="isPopupOpen"
      @close-popup="closePopup"
  />
</template>


<script>
import {retrievePage} from "@/services/apiService"; // Adjust the import path according to your project structure
import SearchPopupComponent from '@/components/General/SearchPopupComponent.vue';

export default {
  name: 'AppNavigationMenu',
  components: {SearchPopupComponent},
  props: {
    isOpen: Boolean,
  },
  data() {
    return {
      navbarElements: [],
      isPopupOpen: false,
      previousFocus: null,
      focusableElements: [],
    };
  },
  watch: {
    isOpen(newVal) {
      if (newVal) {
        // Save current focus position before opening menu
        this.previousFocus = document.activeElement;

        // When the menu is opened, set up the focus trap
        this.$nextTick(() => {
          this.setupFocusTrap();
        });

        // Prevent body scrolling when menu is open
        document.body.style.overflow = 'hidden';
      } else {
        // When the menu is closed, restore focus
        if (this.previousFocus) {
          this.previousFocus.focus();
        }

        // Re-enable body scrolling
        document.body.style.overflow = '';
      }
    }
  },
  mounted() {
    this.loadPage();

    // Add escape key handler to document
    document.addEventListener('keydown', this.handleEscapeKey);
  },
  beforeUnmount() {
    // Clean up event listeners
    document.removeEventListener('keydown', this.handleEscapeKey);
  },
  methods: {
    async loadPage() {
      try {
        const pageData = await retrievePage('NavigationBar');
        this.navbarElements = pageData.attributes.navbar_element.map(item => ({
          title: item.title,
          link: item.link
        }));
      } catch (error) {
        console.error('Error loading page:', error);
        // Optionally update your component's state to reflect the error
      }
    },
    isExternalLink(link) {
      return link && (link.startsWith('http://') || link.startsWith('https://'));
    },
    openPopup() {
      this.isPopupOpen = true;
    },
    closePopup() {
      this.isPopupOpen = false;
      // Return focus to search input when popup closes
      if (this.$refs.searchInput) {
        this.$refs.searchInput.focus();
      }
    },
    closeNav() {
      this.$emit('close-nav');
    },
    setupFocusTrap() {
      // Get all focusable elements in menu
      this.updateFocusableElements();

      // Focus the close button
      if (this.$refs.closeButton) {
        this.$refs.closeButton.focus();
      }
    },
    updateFocusableElements() {
      // Get all focusable elements in the menu
      if (this.$refs.menuContent) {
        this.focusableElements = this.$refs.menuContent.querySelectorAll(
            'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
        );
      }
    },
    handleFirstElementKeydown(event) {
      // If shift+tab is pressed on the first element, move to the last element
      if (event.key === 'Tab' && event.shiftKey) {
        event.preventDefault();
        this.focusLastElement();
      }
    },
    handleLastElementKeydown(event) {
      // If tab is pressed on the last element, move to the first element
      if (event.key === 'Tab' && !event.shiftKey) {
        event.preventDefault();
        this.focusFirstElement();
      }
    },
    focusFirstElement() {
      // Focus the first focusable element (close button)
      if (this.$refs.closeButton) {
        this.$refs.closeButton.focus();
      }
    },
    focusLastElement() {
      // Focus the last interactive element in the menu
      if (this.$refs.lastFocusableElement) {
        this.$refs.lastFocusableElement.focus();
      }
    },
    handleEscapeKey(e) {
      if (e.key === 'Escape' && this.isOpen) {
        this.closeNav();
      }
    }
  }
};
</script>


<style scoped>
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.nav-menu {
  padding: 20px;
  display: flex; /* Set display to flex */
  flex-direction: column; /* Stack children vertically */
  justify-content: flex-start; /* Center children horizontally */
  align-items: flex-start; /* Center children vertically */
  position: fixed;
  gap: 30px;
  top: 0;
  right: -100%;
  width: 400px;
  height: 100%;
  background: #042631;
  transition: right 0.3s;
  z-index: 1000;
}

.nav-menu.open {
  right: 0;
}

.menu-content {
  width: 100%;
  height: 100%;
}

/* Hide menu from tab order when not open */
.nav-menu[aria-hidden="true"] * {
  visibility: hidden;
}

.srch-comp {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin-top: 90px;
  margin-bottom: 40px;
}

.search-input-nav {
  border: none;
  background-color: transparent;
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  color: white;
}

.search-input-nav:focus {
  outline: 2px solid #3BFF3B;
  outline-offset: 2px;
}

.search-input-nav::placeholder {
  color: white;
}

.close-btn {
  position: absolute;
  top: 10px;
  left: 10px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 10px;
}

.close-btn:focus {
  outline: 2px solid #3BFF3B;
  outline-offset: 2px;
}

.close-btn img {
  filter: brightness(0) invert(1);
  height: 35px;
  width: auto;
}

ul {
  list-style-type: none;
  padding: 0;
  text-align: left; /* Optional: align text in the center of each link */
  width: 100%;
}

li a {
  display: block;
  padding: 10px;
  text-decoration: none;
  font-family: "IBMPlexSans";
  font-size: 20px;
  color: #FFFFFF;
  letter-spacing: -0.4px;
  line-height: 20px;
}

li a:focus {
  outline: 2px solid #3BFF3B;
  outline-offset: 2px;
}

@media (max-width: 992px) {
  .nav-menu {
    right: -100%;
    width: 260px;
  }

  .nav-menu.open {
    right: 0;
  }
}
</style>