<template>
  <Header/>
  <div class="container">
    <section class="content">
      <div class="title-box">
        <h1>{{ pageTitle }}</h1>
        <p>{{ pageText }}</p>
      </div>
      <SearchBarComponent @search="filterProfessions"/>
      <p class="scroll-show">
        scorri
        <img style="margin-left: 12px"
             src="@/assets/arrow_right.svg"
             class="arrow-icon"
             alt="" aria-hidden="true"/>
      </p>

      <!-- Replaced BookShelfComponent with the new AlphabetNavigation -->
      <AlphabetNavigation @letterSelected="filterProfessionsByLetter" />

      <div class="legend-container">
        <IconsLegendComponent/>
      </div>

      <LoadingComponent :is-visible="isLoading"></LoadingComponent>

      <div v-if="!isLoading && Object.keys(professionResults).length === 0"
           role="status" class="no-results">
        Nessuna professione trovata. Seleziona un'altra lettera o modifica la ricerca.
      </div>

      <div class="results" aria-live="polite">
        <transition-group name="list" tag="div">
          <div v-for="(profession, title) in professionResults" :key="title" class="result-item">
            <router-link
                :to="{ name: 'ProfessionDetail', params: { identifier: profession.attributes.identifier || 'avvocato-avvocata' }}"
                class="result-item-text">
              <span>{{ title }}</span>
              <div v-if="profession.attributes.mini_description"
                   v-html="profession.attributes.mini_description"
                   class="mini-description"></div>
            </router-link>
            <div style="display: flex; flex-direction: row; gap: 20px">
              <InfoIcon v-if="profession.attributes.related_videos && profession.attributes.related_videos.data && profession.attributes.related_videos.data.length" type="related_videos"></InfoIcon>
              <InfoIcon v-if="profession.attributes.foglia_bianca" type="foglia_bianca"></InfoIcon>
              <InfoIcon v-if="profession.attributes.foglia_verde" type="foglia_verde"></InfoIcon>
            </div>
          </div>
        </transition-group>
      </div>
    </section>
    <AppBottomComponent/>
    <Footer/>
  </div>
</template>

<script>
import AlphabetNavigation from '@/components/ProfessionsBrowsePage/AlphabetNavigation.vue';
import SearchBarComponent from '@/components/General/SearchBarComponent.vue';
import IconsLegendComponent from '@/components/General/IconsLegendComponent.vue';
import InfoIcon from '@/components/General/InfoIcon.vue';
import LoadingComponent from '@/components/General/LoadingScreenComponent.vue';
import Header from '@/components/General/AppHeader.vue';
import Footer from '@/components/General/AppFooter.vue';
import {fetchProfessions, retrievePage} from '@/services/apiService';
import AppBottomComponent from "@/components/General/AppBottomComponent.vue";

export default {
  name: 'ProfessionsBrowsePage',
  components: {
    Header,
    Footer,
    AlphabetNavigation,  // Updated component
    SearchBarComponent,
    LoadingComponent,
    InfoIcon,
    AppBottomComponent,
    IconsLegendComponent
  },
  data() {
    return {
      pageTitle: 'Professioni',
      pageText: 'Per ricercare la figura professionale che ti interessa, clicca sulle lettere per visualizzare l\'elenco alfabetico.',
      professionResults: {},
      allProfessions: {},
      isLoading: true,
      pageMetaTitle: 'Professioni | Atlante Delle Professioni',
      pageMetaDescription: '',
      currentLetter: 'A',
    };
  },
  async mounted() {
    await this.loadPage();
    await this.loadProfessions();
    this.setMetaTags();
  },
  beforeUnmount() {
    this.removeMetaTags();
  },
  computed: {
    pageURL() {
      return window.location.origin + window.location.pathname;
    }
  },
  methods: {
    async loadPage() {
      try {
        const pageData = await retrievePage('Professions');
        this.renderPage(pageData);
      } catch (error) {
        console.error('Error loading page:', error);
      }
    },
    async renderPage(pageData) {
      if (pageData && pageData.attributes) {
        this.pageTitle = pageData.attributes.main_title || 'Professioni';
        this.pageText = pageData.attributes.main_subtitle || '';
        this.pageMetaTitle = pageData.attributes.meta_title || 'Professioni | Atlante Delle Professioni';
        this.pageMetaDescription = pageData.attributes.meta_description || '';
      }
    },
    async loadProfessions() {
      try {
        const professions = await fetchProfessions();
        this.processProfessions(professions);
      } catch (error) {
        console.error('Error loading professions:', error);
      } finally {
        this.isLoading = false;
      }
    },
    processProfessions(professions) {
      // populate denominations and specializations
      const professionsWithDenominations = this.populateDenominationsAndSpecializations(professions);

      this.allProfessions = professionsWithDenominations.reduce((accumulator, current) => {
        if (!current || !current.attributes || !current.attributes.identity) return accumulator;

        const title = current.attributes.identity.title;
        if (!title) return accumulator;

        const startingLetter = title.charAt(0).toUpperCase();
        if (!accumulator[startingLetter]) {
          accumulator[startingLetter] = {};
        }
        // Store the entire profession object instead of just the id
        accumulator[startingLetter][title] = current;
        return accumulator;
      }, {});

      // Sort professions within each letter
      Object.keys(this.allProfessions).forEach(letter => {
        const sortedProfessions = Object.keys(this.allProfessions[letter])
            .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())) // Sort titles case-insensitively
            .reduce((acc, title) => {
              // Rebuild the professions object in sorted order
              acc[title] = this.allProfessions[letter][title];
              return acc;
            }, {});

        this.allProfessions[letter] = sortedProfessions;
      });

      this.filterProfessionsByLetter('A');
    },

    populateDenominationsAndSpecializations(professions) {
      if (!professions || !Array.isArray(professions)) return [];

      const newProfessions = [];

      professions.forEach(profession => {
        // Check if the denominazione array exists and is not empty
        if (profession.attributes && profession.attributes.denominazione &&
            Array.isArray(profession.attributes.denominazione) &&
            profession.attributes.denominazione.length > 0) {

          profession.attributes.denominazione.forEach(denomination => {
            if (!denomination || !denomination.title) return;

            let description = `<p style="margin-top: 15px">Altra denominazione di <span style="font-weight:bold;">${profession.attributes.identity?.title || ''}</span></p>`;
            if (denomination.specializzazione) {
              description = `<p style="margin-top: 15px">Specializzazione di <span style="font-weight:bold;">${profession.attributes.identity?.title || ''}</span></p>`;
            }

            // Create a new profession object for each denomination
            const newProfession = {
              attributes: {
                identifier: profession.attributes.identifier,
                identity: {
                  title: denomination.title,
                },
                mini_description: description,
                related_videos: profession.attributes.related_videos || { data: [] },
                foglia_bianca: profession.attributes.foglia_bianca || false,
                foglia_verde: profession.attributes.foglia_verde || false,
              }
            };
            newProfessions.push(newProfession);
          });
        }
      });

      // Concatenate the new professions to the original professions array
      return [...professions, ...newProfessions];
    },

    filterProfessions(query) {
      // Implementation for search functionality
      console.log('Search query:', query);
    },

    filterProfessionsByLetter(letter) {
      this.currentLetter = letter;
      this.professionResults = this.allProfessions[letter] || {};
    },

    setMetaTags() {
      document.title = this.pageMetaTitle;

      const metaTags = [
        {name: 'title', content: this.pageMetaTitle},
        {name: 'description', content: this.pageMetaDescription},
        {property: 'og:title', content: this.pageMetaTitle},
        {property: 'og:url', content: this.pageURL},
        {property: 'og:type', content: 'website'},
        {property: 'og:description', content: this.pageMetaDescription},
        {property: 'twitter:title', content: this.pageMetaTitle},
        {property: 'twitter:url', content: this.pageURL},
        {property: 'twitter:description', content: this.pageMetaDescription}
      ];

      metaTags.forEach(tagDef => {
        const tag = document.createElement('meta');
        Object.keys(tagDef).forEach(key => {
          tag.setAttribute(key, tagDef[key]);
        });
        tag.setAttribute('data-vue-meta', 'true');
        document.head.appendChild(tag);
      });
    },
    removeMetaTags() {
      const tags = document.querySelectorAll('[data-vue-meta="true"]');
      tags.forEach(tag => tag.parentNode.removeChild(tag));
    }
  },
};
</script>


<style>
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

/* Layout of the main container */
.container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1px;
}

/* Content area styling */
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-bottom: 50px;
  padding-bottom: 40px;
}

/* Title box configuration */
.title-box {
  width: 75%;
  max-width: 1000px;
  align-items: flex-start;
  margin-bottom: 30px;
}

/* Title styling */
.title-box h1 {
  font-size: 110px;
  line-height: 87px;
  letter-spacing: -2px;
  font-weight: normal;
  color: #013747;
  margin-bottom: 0.5em;
  transition: font-size 0.3s ease;
  position: relative;
}

.title-box h1::after {
  content: '';
  display: block;
  position: absolute;
  top: -25px;
  left: 30%;
  width: 60%;
  height: 0.02em;
  background-image: linear-gradient(rgba(50, 205, 50, 0), limegreen 85%, rgba(50, 205, 50, 0));
  transform: translateX(-50%);
}


/* Subtitle styling */
.title-box p {
  font-size: 1.1em;
  color: #666;
  margin-top: 0;
  width: 80%;
}


/* Result list styling */
.results {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 75%;
  max-width: 1050px;
}

.no-results {
  width: 75%;
  max-width: 1050px;
  text-align: center;
  padding: 30px;
  font-size: 1.2em;
  color: #666;
  margin-top: 30px;
}

.mini-description p {
  font-size: 12px;
  line-height: 14px;
  font-family: "Montserrat";
}

/* Individual result item styling */
.result-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid snow;
  padding-top: 10px;
  padding-bottom: 40px;
  margin: 25px;
  color: #013747;
  transition: all 0.2s ease-in;
}

/* Styling for links in result items */
.result-item a {
  color: inherit;
  font-size: 30px;
  line-height: 32px;
  text-decoration: none;
  display: block;
  letter-spacing: -0.4px;
  font-family: "IBMPlexSans";
  margin-right: 40px;
}

.result-item-text {
  transition: all 0.2s ease-in;
  color: #013747;
}

.result-item-text:hover {
  color: white;
  transform: translateX(10px);
}

.result-item-text:focus {
  outline: 3px solid #3BFF3B;
  outline-offset: 3px;
}


/* Secondary text in result items */
.result-item .secondary {
  color: #666;
  font-size: 1em;
  margin-top: 5px;
}

/* Transition styles for entering items */
.list-enter-active {
  transition: opacity 0.8s ease;
}

.list-enter, .list-enter-from {
  opacity: 0;
}

.list-enter-to {
  opacity: 1;
}

/* Styles for leaving items - make exit instant */
.list-leave-active, .list-leave-to {
  opacity: 0;
  transition: opacity 0s;
}

.legend-container {
  display: none;
}

.scroll-show {
  display: none;
}

/* Responsive design adjustments */
@media (max-width: 992px) {
  .title-box h1 {
    font-size: 55px;
    line-height: 44px;
    letter-spacing: -1.5px;
    margin-bottom: 40px;
    padding-top: 28px;
    width: 85%;
  }

  .results {
    width: 90%;
  }

  .result-item a {
    font-size: 20px;
    color: #013747;
    letter-spacing: -0.4px;
    line-height: 20px;
    font-family: "IBMPlexSans";
    margin-right: 15px;
  }

  .legend-container {
    display: block;
  }

  .scroll-show {
    display: block;
    float: right;
    width: 100%;
    text-align: right;
    justify-content: flex-end;
    align-items: center;
    margin-top: 45px;
    margin-right: 40px;
    margin-bottom: 0;
    text-transform: uppercase;
    font-size: 11px;
    color: #013747;
    letter-spacing: 1.29px;
    opacity: 1;
  }
}

@media (max-width: 600px) {
  .title-box p {
    font-size: 1em;
    width: 100%;
  }

  .results {
    width: 90%;
  }

  .title-box {
    width: 90%;
  }
}
</style>